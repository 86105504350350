<template>
  <v-container>
    <v-row wrap justify="true">
      <v-col cols="10" offset-sm="1">
        <v-toolbar>
          <v-app-bar-nav-icon @click="$changeRoute({ name: 'keystoneTools'})">
            <v-icon>arrow_back</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title>[XAndr] Split Audience</v-toolbar-title>
        </v-toolbar>
        <v-card>
          <v-container
            fluid
            grid-list-lg
          >
            <v-row wrap>
              <v-col cols="2">
                <v-text-field label="Member Id"
                              v-model="memberId"
                              v-bind:disabled="loading"
                              required></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field label="Io Test"
                              v-model="ioTest"
                              v-bind:disabled="loading"
                              required></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field label="Io Temoin"
                              v-model="ioTemoin"
                              v-bind:disabled="loading"
                              required></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-btn @click="loadCurrentRepartition()" v-bind:loading="loading" :disabled="!isValid">
                  Load current repartition
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-alert
                  :value="message.msg"
                  :type="message.color"
                  outlined
                >
                  {{message.msg}}
                </v-alert>
              </v-col>
              <v-container v-show="groupedRepartition!==null && groupedRepartition.length>0">
                <v-radio-group v-model="mode" row>
                  <v-radio label="Remove split" value="remove"></v-radio>
                  <v-radio label="Apply split" value="split"></v-radio>
                </v-radio-group>
                <div v-show="mode === 'split'">
                  <v-row>
                  <v-col cols="6">
                    <v-slider step="5"
                              tick-size="2"
                              ticks="always"
                              v-model="newRepartition"
                              class="no-message"
                              max="100"
                              min="0"
                    ></v-slider>
                  </v-col>
                  <v-col cols="3">
                    <v-btn @click="updateRepartition()" v-bind:loading="loading">Change</v-btn>
                  </v-col>
                  </v-row>
                  <v-col cols="6" offset-xs="3" class="justify-center">
                    test {{ newRepartition }}% / temoin {{ 100 - newRepartition }}%
                  </v-col>
                </div>
                <div v-show="mode === 'remove'">
                  <v-btn @click="updateRepartition()" v-bind:loading="loading">Remove</v-btn>
                </div>

                <v-col cols="8">
                  <table class="v-datatable v-table theme--light table">
                    <thead>
                    <tr class="cols">
                      <td colspan="3" class="text-center br">Test ({{ ioTest }})</td>
                      <td colspan="3" class="text-center">Temoin ({{ ioTemoin }})</td>
                    </tr>
                    <tr>
                      <td class="text-center">ids</td>
                      <td class="text-center">group</td>
                      <td class="text-center br">new repartition</td>
                      <td class="text-center">ids</td>
                      <td class="text-center">group</td>
                      <td class="text-center">new repartition</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(group, index) in groupedRepartition" :key="index">
                      <td>
                        <p v-for="o in group['test']['ids']" :key="o.id">{{o.object_type}} {{o.id}}</p>
                      </td>
                      <td class="text-center">
                        <p v-if="group['test']['repartition'] !== undefined">
                          {{showRepartition(group['test']['repartition'])}}
                        </p>
                      </td>
                      <td class="text-center br">
                        <p v-if="group['test']['repartition'] !== undefined">
                          {{showRepartition(proposedRepartition['test'])}}
                        </p>
                      </td>

                      <td>
                        <p v-for="o in group['temoin']['ids']" :key="o.id">{{o.object_type}} {{o.id}}</p>
                      </td>
                      <td class="text-center">
                        <p v-if="group['temoin']['repartition'] !== undefined">
                          {{showRepartition(group['temoin']['repartition'])}}
                        </p>
                      </td>
                      <td class="text-center">
                        <p v-if="group['temoin']['repartition'] !== undefined">
                          {{showRepartition(proposedRepartition['temoin'])}}
                        </p>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-container>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'appnexus_split_audience',
  data: function () {
    return {
      memberId: '',
      ioTest: '',
      ioTemoin: '',
      rawNewRepartition: 50,
      repartition: null,
      loading: false,
      message: {
        msg: '',
        color: 'success'
      },
      mode: 'split'
    }
  },
  methods: {
    resetValue: function () {
      this.newRepartition = 50
      this.repartition = null
      this.message.msg = ''
    },
    showRepartition: function (repartition) {
      if (repartition === null || repartition === undefined) {
        return 'No repartition'
      }
      return repartition[0] + '->' + repartition[1]
    },
    loadCurrentRepartition: async function () {
      /* let repartition = {
        'test': [
          { 'object_type': 'io', 'id': '1234', 'repartition': [0, 39] },
          { 'object_type': 'li', 'id': '234', 'repartition': [0, 39] },
          { 'object_type': 'li', 'id': '5', 'repartition': [0, 39] },
          { 'object_type': 'li', 'id': '6', 'repartition': [0, 49] },
          { 'object_type': 'li', 'id': '7', 'repartition': [0, 30] }
        ],
        'temoin': [
          { 'object_type': 'io', 'id': '9', 'repartition': [40, 99] },
          { 'object_type': 'li', 'id': '8', 'repartition': [40, 99] },
          { 'object_type': 'li', 'id': '7', 'repartition': [40, 92] },
          { 'object_type': 'li', 'id': '5', 'repartition': [50, 90] },
          { 'object_type': 'li', 'id': '5', 'repartition': [50, 97] }
        ]
      } */
      this.resetValue()
      this.loading = true
      let repartition = await this.$apiCaller.getAppnexusSplitAudience(this.memberId, this.ioTest, this.ioTemoin)
      this.loading = false
      if (repartition['status'] === 200) {
        this.message.msg = ''
        this.repartition = repartition['data']

        let mostFrequent = this.mostFrequent(this.groupedRepartition)
        this.newRepartition = this.compute_current_repartition(mostFrequent['test'], mostFrequent['temoin'])
      } else {
        let reason = 'Unknown'
        if (repartition.response.data && repartition.response.data.errors) {
          reason = repartition.response.data.errors
        }
        this.message.msg = 'Error when getting repartiton. Please retry or ask to tech team. Error message: ' + reason
        this.message.color = 'error'
      }
    },
    updateRepartition: async function () {
      this.loading = true
      let repartition = await this.$apiCaller.putAppnexusSplitAudience(this.memberId, this.ioTest, this.ioTemoin,
        this.proposedRepartition['test'], this.proposedRepartition['temoin'])
      this.loading = false
      if (repartition['status'] === 200) {
        this.message.msg = 'repartition is applied with success'
        this.message.color = 'success'

        this.repartition = repartition['data']

        let mostFrequent = this.mostFrequent(this.groupedRepartition)
        this.newRepartition = this.compute_current_repartition(mostFrequent['test'], mostFrequent['temoin'])
      } else {
        let reason = 'Unknown'
        if (repartition.response.data && repartition.response.data.errors) {
          reason = repartition.response.data.errors
        }
        this.message.msg = 'Error when applying repartition. Please retry or ask to tech team. Error message: ' + reason
        this.message.color = 'error'
      }
    },
    compute_current_repartition: function (repTest, repTemoin) {
      if (repTest === null && this.betweenRepartition(repTemoin) != null) {
        return 100 - this.betweenRepartition(repTemoin)
      } else if (repTemoin === null && this.betweenRepartition(repTest) != null) {
        return this.betweenRepartition(repTest)
      } else if (Math.abs(this.betweenRepartition(repTemoin) - this.betweenRepartition(repTest)) === 1) {
        if (this.betweenRepartition(repTemoin) < this.betweenRepartition(repTest)) {
          return 100 - this.betweenRepartition(repTest)
        }
        return this.betweenRepartition(repTemoin)
      }
      return 50
    },
    betweenRepartition: function (repartition) {
      if (repartition === null) {
        return null
      } else if (repartition[0] === 0) {
        return repartition[1]
      } else if (repartition[1] === 99) {
        return repartition[0]
      }
      return null
    },
    mostFrequent: function (formatRepartition) {
      let mostFrequentTest = null
      let mostFrequentTestNb = 0
      let mostFrequentTemoin = null
      let mostFrequentTemoinNb = 0
      for (const i in formatRepartition) {
        let r = formatRepartition[i]
        if ('ids' in r['test'] && mostFrequentTestNb < r['test']['ids'].length && r['test']['repartition'] !== null) {
          mostFrequentTestNb = r['test']['ids'].length
          mostFrequentTest = r['test']['repartition']
        }
        if ('ids' in r['temoin'] && mostFrequentTemoinNb < r['temoin']['ids'].length && r['temoin']['repartition'] !== null) {
          mostFrequentTemoinNb = r['temoin']['ids'].length
          mostFrequentTemoin = r['temoin']['repartition']
        }
      }
      return { // .map(numStr => parseInt(numStr))
        'test': mostFrequentTest,
        'temoin': mostFrequentTemoin
      }
    }
  },
  computed: {
    newRepartition: {
      get: function () {
        return this.rawNewRepartition
      },
      set: function (newValue) {
        if (newValue === 0) {
          this.rawNewRepartition = 1
        } else if (newValue === 100) {
          this.rawNewRepartition = 99
        } else {
          this.rawNewRepartition = newValue
        }
      }
    },
    isValid: function () {
      if (!!this.memberId && !!this.ioTest && !!this.ioTemoin) {
        return true
      }
      return false
    },
    groupedRepartition: function () {
      let group = { 'test': {}, 'temoin': {} }

      for (const u in this.repartition) {
        for (const i in this.repartition[u]) {
          let t = this.repartition[u][i]
          let rep
          if (t['repartition'] !== null) {
            rep = t['repartition'][0] + '-' + t['repartition'][1]
          }
          if (!(rep in group[u])) {
            group[u][rep] = {
              repartition: t['repartition'],
              list_obj: []
            }
          }
          group[u][rep].list_obj.push({
            'object_type': t['object_type'], 'id': t['id']
          })
        }
      }
      let result = []
      for (const ioType in group) {
        let i = 0
        let sortedGroup = Object.values(group[ioType]).sort((a, b) => b.list_obj.length - a.list_obj.length)
        for (const g in sortedGroup) {
          if (!(i in result)) {
            result[i] = { 'test': {}, 'temoin': {} }
          }
          result[i][ioType]['ids'] = sortedGroup[g].list_obj
          result[i][ioType]['repartition'] = sortedGroup[g].repartition
          i++
        }
      }

      return result
    },
    proposedRepartition: function () {
      if (this.mode === 'remove') {
        return {
          test: null,
          temoin: null
        }
      }

      let mostFrequent = this.mostFrequent(this.groupedRepartition)
      if (mostFrequent['test'] === null && mostFrequent['temoin'] === null) {
        return {
          test: [0, this.newRepartition - 1],
          temoin: [this.newRepartition, 99]
        }
      } else if (mostFrequent['test'] !== null && mostFrequent['temoin'] !== null) {
        if (mostFrequent['test'][0] > mostFrequent['temoin'][0]) {
          return {
            test: [100 - this.newRepartition, 99],
            temoin: [0, 100 - this.newRepartition - 1]
          }
        }
        return {
          test: [0, this.newRepartition - 1],
          temoin: [this.newRepartition, 99]
        }
      } else if (mostFrequent['test'] !== null) {
        if (mostFrequent['test'][0] === 0) {
          return {
            test: [0, this.newRepartition - 1],
            temoin: [this.newRepartition, 99]
          }
        }
        return {
          test: [100 - this.newRepartition, 99],
          temoin: [0, 100 - this.newRepartition - 1]
        }
      }
      if (mostFrequent['temoin'][0] === 0) {
        return {
          test: [100 - this.newRepartition, 99],
          temoin: [0, 100 - this.newRepartition - 1]
        }
      }
      return {
        test: [0, this.newRepartition - 1],
        temoin: [this.newRepartition, 99]
      }
    }
  },
  watch: {
    memberId: function () {
      this.resetValue()
    },
    ioTest: function () {
      this.resetValue()
    },
    ioTemoin: function () {
      this.resetValue()
    }
  }
}
</script>

<style scoped>
  .table {
    border: #aaa 1px solid;
    width: 100%;
  }

  .table >>> td {
    border: #aaa 1px solid;
  }

  .table >>> thead {
    background-color: #ebebec;
  }

  .table >>> thead td.cols {
    width: 50%;
  }

  .table >>> thead tr {
    height: 30px;
  }

  .table >>> tbody td {
    width: 16%;
  }

  .table >>> td.br {
    border-right-color: black;
  }

  .table >>> tbody td p {
    margin-bottom: 4px;
  }

 /* .no-message >>> .v-messages {
    display: none;
  }*/
</style>
