<template>
  <v-container>
    <v-row wrap justify="true">
      <v-col cols="6" offset-sm="3">
        <v-toolbar>
          <v-app-bar-nav-icon @click="$changeRoute({ name: 'keystoneTools'})">
            <v-icon>arrow_back</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title>[TheTradeDesk] Check LLD Access</v-toolbar-title>
        </v-toolbar>
        <v-card>
          <v-container
            fluid
            grid-list-lg
          >
            <v-row wrap>
              <v-col>
                <v-text-field label="Partner Id"
                              v-model="partnerId"
                              v-bind:disabled="loading"
                              required
                              class="shrink"></v-text-field>
                <v-col cols="12">
                  <v-row align-center row>

                    <v-checkbox  class=""
                      v-model="withAdvertiserId"
                      label="Advertiser level"
                    ></v-checkbox>
                    <v-text-field small :disabled="!withAdvertiserId || loading" label="advertiser Id"
                                v-model="advertiserId"></v-text-field>
                  </v-row>
                </v-col>

                <v-switch
                  v-model="useRedPrivacy"
                  :label="useRedPrivacy?'use red privacy':'don\'t use red privacy'"
                ></v-switch>

                <v-switch
                  v-model="useScibidsFolder"
                  :label="useScibidsFolder?'use Scibids folder':'Old method access'"
                ></v-switch>

                <template v-if="!useScibidsFolder">
                  <v-text-field small label="Access Key"
                                v-model="bucketAccess.accessKey"
                                v-bind:disabled="loading"></v-text-field>
                  <v-text-field label="Secret Key"
                                v-model="bucketAccess.secretKey"
                                v-bind:disabled="loading"></v-text-field>
                </template>

              </v-col>
              <v-col cols="12">
                <v-btn v-bind:color="buttonColor" :disabled="!isValid" @click="checkAccess()" v-bind:loading="loading">Test access
                </v-btn>
              </v-col>

              {{errorMessage}}
              <v-col cols="12" v-if="isSend">
                <div>Credentials is valid: {{ status.valid_credentials }}</div>
                <div>Have access to aggregated red: {{ status.access_aggregated }}</div>
                <div>Have access to streaming red: {{ status.access_realtime }}</div>
                <div>Have content in aggregated red: {{ status.content_aggregated }}</div>
                <div>Have content in streaming red: {{ status.content_realtime }}</div>

                <div>Have access to attributed conversion: {{ status.access_attributed_conversion }}</div>
                <div>List of advertiser with attributed conversion red: {{ listOfAdvertiserFormated(status.content_attributed_conversion)  }}</div>
              </v-col>
              <v-col cols="12" v-if="isSend">
                <div v-if="accessValid">Good!</div>
                <div v-else>Not good</div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'ttd_access_lld',
  data: function () {
    return {
      partnerId: '',
      useScibidsFolder: true,
      useRedPrivacy: false,
      advertiserId: null,
      withAdvertiserId: false,
      bucketAccess: {
        accessKey: '',
        secretKey: ''
      },
      isSend: false,
      status: {
        valid_credentials: false,
        access_aggregated: false,
        access_realtime: false,
        content_aggregated: false,
        content_realtime: false,
        access_attributed_conversion: false,
        content_attributed_conversion: []
      },
      errorMessage: '',
      loading: false
    }
  },
  methods: {
    checkAccess: async function () {
      this.resetStatus()
      this.loading = true
      let response = await this.$apiCaller.getTTDAccessLLD(this.useScibidsFolder, this.partnerId, this.advertiserId, this.bucketAccess, this.useRedPrivacy)
      this.loading = false
      if (response.status === 200) {
        this.status = response.data
        this.isSend = true
      } else if (response.response && response.response.status === 403) {
        this.errorMessage = 'UNAUTH error, you need additionnal rights to access this LLD access feature, please contact the tech team in the PROD chan'
      } else {
        this.errorMessage = 'There are unknown error, please ask to dev team'
      }
    },
    resetStatus: function () {
      this.status = {
        valid_credentials: false,
        access_aggregated: false,
        access_realtime: false,
        content_aggregated: false,
        content_realtime: false,
        access_attributed_conversion: false,
        content_attributed_conversion: []
      }
      this.errorMessage = ''
      this.isSend = false
    },
    listOfAdvertiserFormated: function (advertisers) {
      if (advertisers.length === 0) {
        return 'None'
      }
      return advertisers.join(', ')
    }
  },
  computed: {
    buttonColor: function () {
      if (!this.isSend) {
        return ''
      }
      if (this.accessValid) {
        return 'success'
      } else if (this.status.access_realtime && this.status.access_aggregated) {
        return 'warning'
      } else {
        return 'error'
      }
    },
    isValid: function () {
      if (!this.partnerId) {
        return false
      }
      if (!this.useScibidsFolder && (!this.bucketAccess.accessKey || !this.bucketAccess.secretKey)) {
        return false
      }
      return true
    },
    accessValid: function () {
      return this.status.valid_credentials && this.status.access_realtime && this.status.access_aggregated && this.status.content_realtime && this.status.content_aggregated
    }
  },
  watch: {
    withAdvertiserId: function (newValue, oldValue) {
      if (!newValue) {
        this.advertiserId = null
      }
    }
  }
}
</script>

<style scoped>
  .largePrefix >>> input {
    width: 30%;
  }
</style>
