<template>
  <v-container>
    <v-row wrap justify="true">
      <v-col cols="6" offset-sm="3">
        <v-toolbar>
          <v-app-bar-nav-icon @click="$changeRoute({ name: 'keystoneTools'})">
            <v-icon>arrow_back</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title>[Appnexus] Replay instru strat error</v-toolbar-title>
        </v-toolbar>
        <v-card>
          <replay-instru-strat-error client-id-column-name="Member" io-id-column-name="Insertion Order" dsp="appnexus"></replay-instru-strat-error>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import ReplayInstruStratError from '@/components/KeystoneToolsComponents/replayInstruStratError.vue'

export default {
  name: 'appnexusReplayInstruStratError',
  components: { ReplayInstruStratError }
}
</script>
