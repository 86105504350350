import splitAudience from '@/components/KeystoneToolsComponents/appnexus/splitAudience'
import * as dv360lldAccess from '../src/components/KeystoneToolsComponents/dv360/lld_access'
import * as ttdlldAccess from '../src/components/KeystoneToolsComponents/ttd/lld_access'
import * as mediamathlldAccess from '../src/components/KeystoneToolsComponents/mediamath/lld_access'
import * as mediamathReplayInstruStrat from '@/components/KeystoneToolsComponents/mediamath/replayInstruStratError.vue'
import * as appnexusReplayInstruStrat from '@/components/KeystoneToolsComponents/appnexus/replayInstruStratError.vue'
import * as dv360ReplayInstruStrat from '@/components/KeystoneToolsComponents/dv360/replayInstruStratError.vue'
import * as ttdReplayInstruStrat from '@/components/KeystoneToolsComponents/ttd/replayInstruStratError.vue'

export const keystoneTools = {
  'appnexus': [
    { 'name': 'split_audience', 'displayName': 'Split audience', component: splitAudience },
    { 'name': 'replay_instru_strat_error', 'displayName': 'Replay Instru Strat Error', component: appnexusReplayInstruStrat.default }
  ],
  'dv360': [
    { 'name': 'lld_access', 'displayName': 'LLD Access', component: dv360lldAccess.default },
    { 'name': 'replay_instru_strat_error', 'displayName': 'Replay Instru Strat Error', component: dv360ReplayInstruStrat.default }
  ],
  'ttd': [
    { 'name': 'lld_access', 'displayName': 'LLD Access', component: ttdlldAccess.default },
    { 'name': 'replay_instru_strat_error', 'displayName': 'Replay Instru Strat Error', component: ttdReplayInstruStrat.default }
  ],
  'mediamath': [
    { 'name': 'lld_access', 'displayName': 'LLD Access', component: mediamathlldAccess.default },
    { 'name': 'replay_instru_strat_error', 'displayName': 'Replay Instru Strat Error', component: mediamathReplayInstruStrat.default }
  ]
}
