<template>
  <v-container>
    <v-row wrap justify="true">
      <v-col cols="6" offset-sm="3">
        <v-toolbar>
          <v-app-bar-nav-icon @click="$changeRoute({ name: 'keystoneTools'})">
            <v-icon>arrow_back</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title>[MediaMath] Replay instru strat error</v-toolbar-title>
        </v-toolbar>
        <v-card>
          <replay-instru-strat-error client-id-column-name="Organization" io-id-column-name="Campaign" dsp="mediamath"></replay-instru-strat-error>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import ReplayInstruStratError from '@/components/KeystoneToolsComponents/replayInstruStratError.vue'

export default {
  name: 'mediamathReplayInstruStratError',
  components: { ReplayInstruStratError }
}
</script>
