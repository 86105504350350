<template>
  <v-container>
    <v-row align-center align-content-center>
      <v-col xs="12" lg="8">
        <v-toolbar>
          <v-app-bar-nav-icon @click="$changeRoute({ name: 'keystoneTools'})">
            <v-icon>arrow_back</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title>[DV360] Check LLD Access</v-toolbar-title>
        </v-toolbar>
        <v-card>
          <v-container
            fluid
            grid-list-lg
          >
            <v-row wrap>
              <v-col cols="12">
                <v-tabs fixed-tabs>
                  <v-tab v-for="(bucket, index) in bucketType" v-bind:key="bucket.prefix"
                         v-on:click="changeBucketType(index)" v-bind:disabled="loading">
                    {{ bucket.name }}
                  </v-tab>
                </v-tabs>
              </v-col>
              <v-col>
                <v-text-field label="Bucket Id" class="shrink largePrefix"
                              v-bind:prefix="bucketType[selectedBucketType].prefix"
                              v-model="bucketId"
                              v-bind:disabled="loading"
                              required></v-text-field>

              </v-col>
              <v-col cols="12">
                <v-btn v-bind:color="buttonColor" :disabled="!isValid" @click="checkAccess()" v-bind:loading="loading">Test access
                </v-btn>
              </v-col>

              <v-col cols="12">
                <div>{{ status.message }}</div>
                <ul v-if="suggestPrefix && suggestPrefix.length>0">
                  The set-up of this account  can't be done by using the generic method, it'll need the addition of a prefix in the config project
                  <li v-for="prefix in suggestPrefix" :key="prefix.prefix">
                    prefix name: <b>{{prefix.prefix}}</b>,
                    start date access: {{prefix.min_day}},
                    end date access: {{prefix.max_day}}
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'dv360_access_lld',
  data: function () {
    return {
      bucketType: [
        { name: 'DBM Partner', prefix: 'dcdt_-dbm_partner' },
        { name: 'DBM Advertiser', prefix: 'dcdt_-dbm_advertiser' },
        { name: 'DCM Account', prefix: 'dcdt_-dcm_account' },
        { name: 'DCM Advertiser', prefix: 'dcdt_-dcm_advertiser' }
      ],
      selectedBucketType: 0,
      status: {
        is_success: undefined,
        message: ''
      },
      bucketId: '',
      loading: false,
      suggestPrefix: []
    }
  },
  methods: {
    changeBucketType: function (index) {
      this.selectedBucketType = index
    },
    checkAccess: async function () {
      this.resetStatus()
      this.loading = true
      let response = await this.$apiCaller.getDV360AccessLLD(this.completeBucketName)
      this.loading = false
      if (response.status === 200) {
        this.status.is_success = response.data.valid
        this.status.message = ''
        if (!this.status.is_success) {
          this.status.message = response.data.reason
        } else {
          this.status.message = 'Access confirmed for ' + this.completeBucketName
        }
        if (response.data.suggest_prefix !== null) {
          let suggestPrefix = response.data.suggest_prefix
          if (suggestPrefix.length !== 1 || !suggestPrefix[0]['prefix'].startsWith('dcm_account')) {
            this.status.message = 'We don\'t have access to all the advertisers of this bucket ' + this.completeBucketName
            this.suggestPrefix = suggestPrefix
          } else if (suggestPrefix.length === 0) {
            this.status.is_success = false
            this.status.message = 'No access to bucket' + this.completeBucketName
          }
        }
      } else if (response.response && response.response.status === 403) {
        this.status.message = 'UNAUTH error, you need additionnal rights to access this LLD access feature, please contact the tech team in the PROD chan'
        this.status.is_success = false
      } else {
        this.status.message = 'There are unknown error, please ask to dev team'
        this.status.is_success = false
      }
    },
    resetStatus: function () {
      this.status = {
        is_success: undefined,
        message: ''
      }
      this.suggestPrefix = ''
    }
  },
  computed: {
    buttonColor: function () {
      switch (this.status.is_success) {
        case true:
          return 'success'
        case false:
          return 'error'
      }
      return ''
    },
    completeBucketName: function () {
      return this.bucketType[this.selectedBucketType]['prefix'] + this.bucketId
    },
    isValid: function () {
      return !!this.bucketId
    }
  },
  watch: {
    completeBucketName: function () {
      this.resetStatus()
    }
  }
}
</script>

<style scoped>
  .largePrefix >>> input {
    width: 40px;
  }
</style>
