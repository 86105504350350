<template>
  <v-container
    fluid
    grid-list-lg
  >
    <v-row wrap>
      <v-col>
        <v-radio-group
          v-model="level"
          row
        >
          <v-radio
            :label="clientIdColumnName"
            value="client"
          ></v-radio>
          <v-radio
            :label="advertiserIdColumnName"
            value="advertiser"
          ></v-radio>
          <v-radio
            :label="ioIdColumnName"
            value="io"
          ></v-radio>
        </v-radio-group>
        <v-text-field label="Ids split with ,"
                      v-model="ids"
                      v-bind:disabled="loading"
                      required
                      class="shrink"></v-text-field>
      </v-col>
      <v-col cols="12">
        <div>The instructions will change from status error to to_push and launch apply strat. Only the instructions inserted in the last 2 days will be taken into consideration</div>
        <v-btn :disabled="!isValid" @click="replay()" v-bind:loading="loading">Replay
        </v-btn>
      </v-col>

      {{ errorMessage }}
      <v-col cols="12" v-if="isSend">
        <div>{{ clientIdColumnName }} id impacted: {{ result.clientIds.join(", ") }}</div>
        <div>Instruction changed to to_push: {{ result.nbLinesModified }}</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'replayInstruStratError',
  data: function () {
    return {
      ids: '',
      level: 'client',
      isSend: false,
      result: {
        clientIds: [],
        nbLinesModified: 0
      },
      errorMessage: '',
      loading: false
    }
  },
  props: {
    dsp: String,
    clientIdColumnName: String,
    ioIdColumnName: String,
    advertiserIdColumnName: {
      type: String,
      default: 'Advertiser'
    }
  },
  methods: {
    replay: async function () {
      this.resetStatus()
      this.loading = true
      let ids = this.ids.split(',').map(element => element.trim())
      let response = await this.$apiCaller.postReplayInstruStratError(this.dsp, this.level, ids)
      this.loading = false
      if (response.status === 200) {
        this.result.clientIds = response.data['client_ids']
        this.result.nbLinesModified = response.data['nb_lines_modified']
        this.isSend = true
      } else if (response.response && response.response.status === 403) {
        this.errorMessage = 'UNAUTH error, you need additionnal rights to access this LLD access feature, please contact the tech team in the PROD chan'
      } else {
        this.errorMessage = 'There are unknown error, please ask to dev team'
      }
    },
    resetStatus: function () {
      this.result = {
        clientIds: [],
        nbLinesModified: 0
      }
      this.errorMessage = ''
      this.isSend = false
    }
  },
  computed: {
    isValid: function () {
      if (!this.ids) {
        return false
      }
      return true
    }
  }
}
</script>
