<template>
  <v-container>
    <v-row wrap justify="true">
      <v-col cols="6" offset-sm="3">
        <v-toolbar>
          <v-app-bar-nav-icon @click="$changeRoute({ name: 'keystoneTools'})">
            <v-icon>arrow_back</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title>[DV360] Replay instru strat error</v-toolbar-title>
        </v-toolbar>
        <v-card>
          <v-container>
            <v-radio-group
              v-model="subDsp"
              row
            >
              <v-radio
                label="DBM"
                value="dbm"
              ></v-radio>
              <v-radio
                label="Trueview"
                value="trueview"
              ></v-radio>
            </v-radio-group>
          <replay-instru-strat-error client-id-column-name="Partner" io-id-column-name="Insertion Order" :dsp="subDsp"></replay-instru-strat-error>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import ReplayInstruStratError from '@/components/KeystoneToolsComponents/replayInstruStratError.vue'

export default {
  name: 'dv360ReplayInstruStratError',
  components: { ReplayInstruStratError },
  data: function () {
    return {
      subDsp: 'dbm'
    }
  }
}
</script>
