<template>
  <v-container>
    <v-row wrap justify="true">
      <v-col cols="6" offset-sm="3">
        <v-toolbar>
          <v-app-bar-nav-icon @click="$changeRoute({ name: 'keystoneTools'})">
            <v-icon>arrow_back</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title>[MediaMath] Check LLD Access</v-toolbar-title>
        </v-toolbar>
        <v-card>
          <v-container
            fluid
            grid-list-lg
          >
            <v-row wrap>
              <v-col>
                <v-text-field label="Organization Id"
                              v-model="organizationId"
                              v-bind:disabled="loading"
                              required
                              type="number"
                              class="shrink"></v-text-field>
                <v-col cols="12">
                  <v-row align-center row>

                    <v-checkbox  class=""
                      v-model="withAdvertiserId"
                      label="Advertiser level"
                    ></v-checkbox>
                    <v-text-field small :disabled="!withAdvertiserId || loading" label="agency Id"
                                  type="number"
                                  v-model="agencyId"></v-text-field>
                    <v-text-field small :disabled="!withAdvertiserId || loading" label="advertiser Id"
                                  type="number"
                                v-model="advertiserId"></v-text-field>
                  </v-row>
                </v-col>

              </v-col>
              <v-col cols="12">
                <v-btn v-bind:color="buttonColor" :disabled="!isValid" @click="checkAccess()" v-bind:loading="loading">Test access
                </v-btn>
              </v-col>

              {{errorMessage}}
              <v-col cols="12" v-if="isSend">
                <div v-if="accessAllowed">Good!</div>
                <div v-else>Not good</div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'mediamath_access_lld',
  data: function () {
    return {
      organizationId: null,
      agencyId: null,
      advertiserId: null,
      withAdvertiserId: false,
      isSend: false,
      accessAllowed: false,
      errorMessage: '',
      loading: false
    }
  },
  methods: {
    checkAccess: async function () {
      this.resetStatus()
      this.loading = true
      let level = 'organization'
      if (this.withAdvertiserId) {
        level = 'advertiser'
      }

      let response = await this.$apiCaller.getMediaMathAccessLLD(this.organizationId, this.agencyId, this.advertiserId, level)
      this.loading = false
      if (response.status === 200) {
        this.accessAllowed = response.data['access_allowed']
        this.isSend = true
      } else if (response.response && response.response.status === 403) {
        this.errorMessage = 'UNAUTH error, you need additionnal rights to access this LLD access feature, please contact the tech team in the PROD chan'
      } else {
        this.errorMessage = 'There are unknown error, please ask to dev team'
      }
    },
    resetStatus: function () {
      this.access_allowed = false
      this.errorMessage = ''
      this.isSend = false
    }
  },
  computed: {
    buttonColor: function () {
      if (!this.isSend) {
        return ''
      }
      if (this.accessAllowed) {
        return 'success'
      } else {
        return 'error'
      }
    },
    isValid: function () {
      if (!this.organizationId) {
        return false
      }
      if (this.withAdvertiserId && (!this.agencyId || !this.advertiserId)) {
        return false
      }
      return true
    }
  },
  watch: {
    withAdvertiserId: function (newValue, oldValue) {
      if (!newValue) {
        this.agencyId = null
        this.advertiserId = null
      }
    }
  }
}
</script>

<style scoped>
  .largePrefix >>> input {
    width: 30%;
  }
</style>
